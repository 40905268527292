import React from 'react';

import 'assets/styles/app.scss';

import {
  LinkButton,
  IconButton,
} from '@moonshineragency/ui/src/components/Button/Button';
import Card from '@moonshineragency/ui/src/components/Card/Card';
import Steps, { Step } from '@moonshineragency/ui/src/components/Steps/Steps';
import Navigation from '@moonshineragency/ui/src/components/Navigation/Navigation';

import Form, {
  Field,
  FieldGroup,
  RadioGroup,
  SubmitButton,
} from '@moonshineragency/ui/src/components/Forms/Forms';

import Container from 'components/Container/Container';
import Layout from 'components/Layout/Layout';

const ExamplePage1 = () => {
  return (
    <Layout
      title="Stromanschluss Online für Elektrounternehmen"
      renderHeaderNav
    >
      <Navigation />
      <Container className="py-3">
        <div className="d-flex">
          <div className="w-35 overflow-hidden mr-3">
            <div>
              <h2 className="text-truncate">
                Installationsdokument
                <br /> (Erzeugungsanlage / Batteriespeicher)
              </h2>
              <h3>Neuer Auftrag FE-76787877 vom 12.12.2019</h3>
            </div>
            <Steps
              className="align-items-start"
              active="electrician"
              orientation="vertical"
            >
              <Step id="customer" title="Kunde und Anschluss" icon="home">
                <div className="mb-3 justify-self-start">
                  <h5 className="m-0 text-reset">Kunde</h5>
                  <div>Doris Baumann</div>
                </div>
                <div className="mb-3">
                  <h5 className="m-0 text-reset">Standort des Anschlusses</h5>
                  <div>Teichmanns 2</div>
                  <div>23623 Kottes-Purk</div>
                </div>
                <div className="mb-3">
                  <h5 className="m-0 text-reset">Zuständiger Mitarbeiter</h5>
                  <div>Markus Michaelovic</div>
                  <div>0664/1243213</div>
                </div>
              </Step>
              <Step id="electrician" title="Technische Details" icon="wrench" />
              <Step id="check" title="Auftrag prüfen" icon="check" />
            </Steps>
          </div>
          <div className="flex-grow-1">
            <Form>
              <Card className="mb-3" title="Kundendaten">
                <div>
                  <RadioGroup
                    labelClassName="font-weight-normal"
                    inline
                    items={[
                      {
                        name: 'privat',
                        label: 'Privatkunde',
                      },
                      {
                        name: 'geschaeft',
                        label: 'Geschäftskunde',
                      },
                    ]}
                    name="kundentyp"
                  />
                </div>
                <div className="my-2">
                  <Field
                    name="kundennummer"
                    label="Kundennummer"
                    hint="Kunden finden Ihre Kundennummer auf den Rechnungen der Netz NÖ, z.B. 10011234"
                    placeHolder="falls vorhanden"
                  />
                </div>
                <div className="my-2">
                  <RadioGroup
                    inline
                    labelClassName="font-weight-normal"
                    label="Anrede"
                    items={[
                      {
                        name: 'frau',
                        label: 'Frau',
                      },
                      {
                        name: 'herr',
                        label: 'Herr',
                      },
                      {
                        name: 'divers',
                        label: 'Divers',
                      },
                    ]}
                    name="anrede"
                  />
                </div>
              </Card>
              <Card title="Erweiterte Angaben">
                <RadioGroup
                  label="Schutzkonzept"
                  name="konzept"
                  items={[
                    {
                      label: (
                        <>
                          <div>Netzentkopplungsschutz</div>
                          <IconButton
                            className="mt-1"
                            theme="secondary"
                            size="50"
                          >
                            <span className="icon time mr-2" />
                            Einstell-/Prüfbericht ausfüllen
                          </IconButton>
                        </>
                      ),
                      name: 'netzentkopplungsschutz',
                    },
                    {
                      label: 'Selbstätig wirkende Freischaltstelle',
                      name: 'selbstätig_wirkende_freischaltstelle',
                    },
                  ]}
                />
                <FieldGroup
                  label="Blindleistungskapazität bei Nennleistung"
                  name="blindleistungskapazität"
                  items={[
                    {
                      name: 'induktiv',
                      hint: 'cos() induktiv',
                      className: 'mr-2 flex-grow-1',
                    },
                    {
                      name: 'kapazitiv',
                      hint: 'cos() kapazitiv',
                      className: 'flex-grow-1',
                    },
                  ]}
                />
              </Card>
              <Card>
                <FieldGroup
                  name="leistung"
                  items={[
                    {
                      label: 'Nennscheinleistung',
                      name: 'nennscheinleistung',
                      className: 'mr-2 flex-grow-1',
                      children: <span className="ml-1">kVA</span>,
                    },
                    {
                      label: 'Max Wirkleistung',
                      name: 'max wirkleistung',
                      className: 'mr-2 flex-grow-1',
                      children: <span className="ml-1">kW</span>,
                    },
                    {
                      label: 'Modulleistung',
                      name: 'modulleistung',
                      className: 'flex-grow-1',
                      children: <span className="ml-1">kWp</span>,
                    },
                  ]}
                />
              </Card>
              <div className="mt-4 d-flex justify-content-between">
                <LinkButton theme="secondary">Verwerfen und zurück</LinkButton>
                <SubmitButton theme="primary">
                  Speichern und zurück
                </SubmitButton>
              </div>
            </Form>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default ExamplePage1;
